import { Box, styled } from "@mui/system"
import { colorPalette } from "../../../../../../../../../styling/theme"

const FileDropzone = styled("div")({
  border: "2px dashed",
  borderColor: colorPalette.deepPurpleO30,
  borderRadius: "8px",
  padding: "40px",
  textAlign: "center",
  transition: "all 0.3s ease",
  backgroundColor: "#f9f9f9",
  marginBottom: "20px",
  "&.active": {
    borderColor: colorPalette.deepPurpleO70,
    backgroundColor: colorPalette.deepPurpleO10,
  },
})

const FileList = styled(Box)({
  border: "2px dashed transparent",
  "&.active": {
    borderRadius: "8px",
    borderColor: colorPalette.deepPurpleO70,
    backgroundColor: colorPalette.deepPurpleO10,
  },
})

export const S = { FileDropzone, FileList }
