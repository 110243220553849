import { Box, Stack, Typography } from "@mui/material"
import React from "react"
import {
  AccessRoleId,
  type ResourceSubject,
  UserType,
} from "../../../../../../../../../../utils/api/AccessManagement/resourceAccess.types"
import AccesGroupHeader from "../AccesGroupHeader"
import AddingUser from "./components/AddingUser"
import SubjectRelation from "./components/SubjectRelation"

type Props = {
  users: ResourceSubject[]
  name: string
  roleId: AccessRoleId
  description: string
  addButtonText: string
  resourceUuid: string
  noUsersText?: string
}

const ResourceUsers = (p: Props) => {
  const noUsers = p.users.length === 0

  const groupIds = p.users.filter(user => user.type === UserType.group).map(user => user.id)

  return (
    <Box minWidth={300} minHeight={250}>
      <AccesGroupHeader groupName={p.name} explanationText={p.description} />
      {noUsers && <Typography>{p.noUsersText}</Typography>}
      {!noUsers && (
        <Stack spacing={1}>
          {p.users.map(user => (
            <SubjectRelation
              key={user.id}
              {...user}
              showDelete={p.roleId !== AccessRoleId.Admin}
              roleId={p.roleId}
              resourceUuid={p.resourceUuid}
              subjectType={user.type}
            />
          ))}
        </Stack>
      )}
      <AddingUser addButtonText={p.addButtonText} roleId={p.roleId} resourceUuid={p.resourceUuid} groupIds={groupIds} />
    </Box>
  )
}

export default React.memo(ResourceUsers)
