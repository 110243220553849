import ClearIcon from "@mui/icons-material/Clear"
import GroupIcon from "@mui/icons-material/Group"
import SmartToyIcon from "@mui/icons-material/SmartToy"
import { Avatar, Box, IconButton } from "@mui/material"
import React, { useEffect } from "react"
import { Loader } from "../../../../../../../../../../../components/Loader/Loader"
import { colorPalette } from "../../../../../../../../../../../styling/theme"
import {
  UserType,
  type AccessRoleId,
  type ResourceSubject,
  type SubjectGroup,
  type SubjectService,
  type SubjectUser,
} from "../../../../../../../../../../../utils/api/AccessManagement/resourceAccess.types"
import { useRemoveResourceSubjectMutation } from "../../../../../../../../../../../utils/redux/queries/access.queries"
import { setAccessLevelErrorRA } from "../../../../../../../../../../../utils/redux/reducers/datasetsReducer"
import { useAppDispatch } from "../../../../../../../../../../../utils/redux/store"

const SubjectRelation = (
  p: ResourceSubject & { showDelete?: boolean; resourceUuid: string; roleId: AccessRoleId; subjectType: UserType }
) => {
  const [removeSubject, { isLoading, isSuccess, error }] = useRemoveResourceSubjectMutation()
  const dispatch = useAppDispatch()
  const contribUser =
    p.subjectType === UserType.user
      ? (p as SubjectUser).email
      : p.subjectType === UserType.group
      ? (p as SubjectGroup).name
      : (p as SubjectService).name

  const removeContributor = async (contribUser: string, subType: UserType) => {
    const confirmed = window.confirm(`Are you sure you want to remove ${contribUser} from the contributors?`)
    if (confirmed) {
      await removeSubject({
        resourceId: p.resourceUuid,
        subjectId: p.id,
        roleId: p.roleId,
        subjectType: subType,
      })
    }
  }

  useEffect(() => {
    if (isSuccess) {
      if (p.subjectType === UserType.user) {
        alert("User has been removed from the contributors")
      }
      if (p.subjectType === UserType.group) {
        alert("Group has been removed from the contributors")
      }
      if (p.subjectType === UserType.service) {
        alert("App Service has been removed from the contributors")
      }
    }
  }, [p.subjectType, isSuccess])

  useEffect(() => {
    dispatch(setAccessLevelErrorRA(error))
  }, [error, dispatch])

  return (
    <>
      {isLoading && (
        <Box display={"flex"} justifyContent={"center"} sx={{ py: 1 }}>
          <Loader size={25} />
        </Box>
      )}
      {!isLoading && (
        <Box display={"flex"} alignItems={"center"}>
          <Avatar>
            {p.subjectType === UserType.user && contribUser[0].toUpperCase()}
            {p.subjectType === UserType.group && <GroupIcon />}
            {p.subjectType === UserType.service && <SmartToyIcon />}
          </Avatar>
          <Box marginLeft={1}>{contribUser}</Box>
          {p.showDelete && (
            <IconButton
              sx={{ ml: "auto" }}
              onClick={async () => await removeContributor(contribUser, p.subjectType)}
              disabled={isLoading}>
              <ClearIcon sx={{ color: colorPalette.deepPurpleO20 }} />
            </IconButton>
          )}
        </Box>
      )}
    </>
  )
}

export default React.memo(SubjectRelation)
