import type { Dayjs } from "dayjs"
import { paths } from "../../components/navigation.helpers"
import { type SpecialFacetsApi } from "../../pages/CatalogDataset/components/DataInteraction/dataInteraction.helpers"
import { type AttributeDto, type Facets } from "../sdk/utils/entities/sdk.dataset.types"
import { ResourceKind } from "../sdk/utils/entities/sdk.resource.types"
import type { Nullable } from "../utils"
import type { PersonContact } from "./dataCollection.types"
import type { DataEntity } from "./dataEntity.types"

export type LoggedInUser = {
  name?: string
  email?: string
  family_name?: string
  given_name?: string
  groups?: string[]
  azureId: string
  isInternal?: boolean
  isNonWhitelisted: boolean
}

export type UploadedFile = {
  fileName: string
}

export enum DatasetType {
  Files = "raw",
  Tabular = "tabular",
}

export type FillableMetadataDataset = Nullable<
  PersonContact & {
    maintanierOrganisation: string
    citation: string
    doi: string
  }
>

export const fillableMetadataDatasetProps: (keyof FillableMetadataDataset)[] = [
  "citation",
  "doi",
  "maintanierOrganisation",
  "contactEmail",
  "contactLastName",
  "contactFirstName",
]

export type DatasetMetadata = Partial<Nullable<FillableMetadataDataset>>

export type DataSet = DataEntity &
  Nullable<{
    files?: UploadedFile[]
    fillableMetadata?: DatasetMetadata
    catalogEntryId?: string
    attributes?: AttributeDto[]
    collectionId?: string
    otherFacets?: Facets
    specialFacets?: Partial<SpecialFacetsApi>
  }> & {
    type?: DatasetType
  }

export type CreateDataSet = Omit<DataSet, "uuid" | "nameId" | "isPublic" | "ownerId">

export type BoundingMapboxCoords = number[][]
export type TimeRangeSeconds = [number, number]
export type TimeRangeMiliSeconds = [number, number]

export const TRAIT_REGEX = /^(is|does|has|means)(\.\w+)+$/

export const createMyDatasetPath = (datasetId: string) => `${paths.Datasets}/${datasetId}`
export const createMyCollectionPath = (collectionId: string) => `${paths["My Data"]}/${collectionId}`

export const collectionIdFromRef = (collectionRef: string) => collectionRef.replace(`${ResourceKind.collection}/`, "")

export const shortenDescription = (desc: string, nChars = 140) => {
  if (desc.length <= nChars) return desc
  else return desc.substring(0, nChars) + "..."
}

/**
 * Converts year to unix timestamp. Handles years < 1000
 */
const yearToSeconds = (year: number): number => {
  const date = new Date(Date.UTC(year, 0, 1, 0, 0, 0, 0))
  date.setUTCFullYear(year)
  return date.getTime() / 1000
}

export const createTimeRangeSeconds = (startYear?: number, endYear?: number): TimeRangeSeconds | undefined => {
  if (!startYear && !endYear) return

  const yearRange = [startYear ?? 0, endYear ?? 9999]
  return yearRange.map(yearToSeconds) as [number, number]
}

const convertSecondsToDate = (seconds: number) => new Date(seconds * 1000)
export const convertSecondsToDateString = (seconds: number) => convertSecondsToDate(seconds).toLocaleDateString()
export const convertDateStringToSeconds = (dateString: Dayjs) => new Date(dateString.toISOString()).getTime() / 1000
