import React from "react"
import KeyValueDisplay from "../../../../../components/KeyValueDisplay/KeyValueDisplay"
import MetadataBlockDisplay from "../../../../../components/MetadataBlockDisplay"
import type {
  CollectionMetadata,
  LicenseMetadata,
  PublisherContact,
} from "../../../../../utils/frontendTypes/dataCollection.types"
import type { Nullable } from "../../../../../utils/utils"

type Props<T extends Record<string, string>> = {
  metadata: CollectionMetadata
  fillable: (keyof T)[]
  keyTransformFn: (key: string) => string
}

const CollectionMetadataDisplay = <T extends Record<string, string>>(p: Props<T>) => {
  const publisherMetadata: Partial<Nullable<PublisherContact>> = {
    publisherContactOrganisation: p.metadata.publisherContactOrganisation,
    contactFirstName: p.metadata.contactFirstName,
    contactLastName: p.metadata.contactLastName,
    contactEmail: p.metadata.contactEmail,
  }

  const publlisherKeyNamesMap = {
    publisherContactOrganisation: "Organisation",
    contactFirstName: "First name",
    contactLastName: "Last name",
    contactEmail: "Email",
  }

  const licenseMetadata: Partial<Nullable<LicenseMetadata>> = {
    licenseFullText: p.metadata.licenseFullText,
    licenseHref: p.metadata.licenseHref,
    licenseName: p.metadata.licenseName,
  }

  const licenseKeyNamesMap = {
    licenseFullText: "Text",
    licenseHref: "Href",
    licenseName: "Name",
  }

  return (
    <>
      <MetadataBlockDisplay<Partial<Nullable<PublisherContact>>>
        title="Publisher"
        metadata={publisherMetadata}
        keyNamesMap={publlisherKeyNamesMap}
        sx={{ mb: 1 }}
      />
      <KeyValueDisplay objectKey="Published date" value={p.metadata.publishedDate} />

      <MetadataBlockDisplay<Partial<Nullable<LicenseMetadata>>>
        title="License"
        metadata={licenseMetadata}
        keyNamesMap={licenseKeyNamesMap}
        sx={{ mb: 1 }}
      />
      <KeyValueDisplay objectKey="Website" value={p.metadata.website} />
    </>
  )
}

export default React.memo(CollectionMetadataDisplay)
