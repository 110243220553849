import AddCircleIcon from "@mui/icons-material/AddCircle"
import GroupIcon from "@mui/icons-material/Group"
import {
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material"
import type { TransitionProps } from "@mui/material/transitions"
import React, { useEffect, useState } from "react"
import { StyledButton } from "../../../../../../../../../../../components/StyledButton"
import {
  AccessRoleId,
  UserType,
  type AccessGroup,
} from "../../../../../../../../../../../utils/api/AccessManagement/resourceAccess.types"
import {
  useAddResourceUserMutation,
  useGetUserAccessGroupsQuery,
} from "../../../../../../../../../../../utils/redux/queries/access.queries"

type Props = {
  resourceId: string
  roleName: string
  contributorGroups: string[]
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />
})

const UserAccessGroups = (p: Props) => {
  const { data: groups, isFetching } = useGetUserAccessGroupsQuery()
  const [addGroup] = useAddResourceUserMutation()

  const [openClick, setOpenClick] = useState(false)
  const [groupName, setGroupName] = useState("")
  const [groupId, setGroupId] = useState("")
  const [roleName, setRoleName] = useState("")
  const [openErrorDialog, setOpenErrorDialog] = useState(false)
  const [errorText, setErrorText] = useState("")
  const [availGroups, setAvailGroups] = useState<AccessGroup[]>([])

  useEffect(() => {
    const availableGroups = groups?.filter(el => !p.contributorGroups.includes(el.id)) || []
    setAvailGroups(availableGroups)
  }, [groups, p.contributorGroups])

  const addGrouptoContributors = (groupName: string, groupId: string, roleName: string) => {
    setOpenClick(true)
    setGroupName(groupName)
    setGroupId(groupId)
    setRoleName(roleName)
  }

  const getRoleId = (roleName: string) => {
    if (roleName === "Admins") {
      return AccessRoleId.Admin
    } else if (roleName === "Editors") {
      return AccessRoleId.Editor
    } else {
      return AccessRoleId.Viewer
    }
  }

  const removeGroup = (groupId: string) => {
    const newGroups = availGroups?.filter(el => el.id !== groupId)
    setAvailGroups(newGroups)
  }

  const handleYes = () => {
    addGroup({
      resourceId: p.resourceId,
      userId: groupId,
      roleId: getRoleId(roleName),
      userType: UserType.group,
    })
      .then(res => {
        setOpenClick(false)
        if (res.error && "status" in res.error) {
          setOpenErrorDialog(true)
          console.error(res.error)
          if (res.error.status === 409) {
            setErrorText("This group is already added in other roles")
          } else {
            setErrorText((res.error.data as { message: string }).message)
          }
        } else {
          removeGroup(groupId)
        }
      })
      .catch(e => {
        console.error(e)
      })
  }

  const handleNo = () => {
    setOpenClick(false)
  }

  const handleCloseError = () => {
    setOpenErrorDialog(false)
  }

  return (
    <>
      {isFetching && <CircularProgress size="30px" />}
      {!isFetching &&
        availGroups?.map(el => (
          <Chip
            key={el.name}
            sx={{ marginRight: 0.5, marginTop: 1 }}
            icon={<GroupIcon />}
            label={el.name}
            onDelete={() => addGrouptoContributors(el.name, el.id, p.roleName)}
            deleteIcon={<AddCircleIcon />}
          />
        ))}
      <Dialog
        open={openClick}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleNo}
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle>Add Group ?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to add {groupName} group as {roleName}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <StyledButton onClick={() => handleYes()}>Yes</StyledButton>
          <StyledButton onClick={() => handleNo()}>No</StyledButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openErrorDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseError}
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">{errorText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <StyledButton onClick={() => handleCloseError()}>Ok</StyledButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default UserAccessGroups
