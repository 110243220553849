/* eslint-disable multiline-ternary */
import ArticleIcon from "@mui/icons-material/Article"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CloseIcon from "@mui/icons-material/Close"
import ErrorIcon from "@mui/icons-material/Error"
import UploadFileIcon from "@mui/icons-material/UploadFile"
import WarningIcon from "@mui/icons-material/Warning"
import { Alert, IconButton, lighten, ListItem, ListItemIcon, ListItemText, Typography, useTheme } from "@mui/material"
import React, { forwardRef, useCallback, useImperativeHandle, useState } from "react"
import { StyledButton } from "../../../../../../../../../components/StyledButton"
import { slugify } from "../../../../../../../../../utils/api/requests.utils"
import { getAccessToken } from "../../../../../../../../../utils/auth/msalConfig"
import { type DataSet } from "../../../../../../../../../utils/frontendTypes/datasets.types"
import { useCreateRawFileMetadataMutation } from "../../../../../../../../../utils/redux/queries/raw.queries"
import { uploadFile } from "../../rawFileUpload.helpers"
import UploadProgress from "../../UploadProgress"
import MimeTypeDialog from "./MimeTypeDialog"

type Props = {
  dataset: DataSet
  file: File
  onUploadComplete: () => void
  onUploadError?: () => void
  removeFile: (fileName: string) => void
  updateFile: (fileName: string, updatedFile: File) => void
}

// Define the ref interface
export type FileUploadRef = {
  uploadFile: () => Promise<void>
  resetUpload: () => void
  isCompleted: () => boolean
  isError: () => boolean
  hasMimeType: () => boolean
}

const FileToUpload = forwardRef<FileUploadRef, Props>((props, ref) => {
  const { dataset, file, onUploadComplete, onUploadError, removeFile, updateFile } = props
  const [createFileMetadata, { error: metadataError }] = useCreateRawFileMetadataMutation()
  const [uploadProgress, setUploadProgress] = useState<number>(0)
  const [uploadStatus, setUploadStatus] = useState<"idle" | "uploading" | "complete" | "error">("idle")
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [mimeTypeDialogOpen, setMimeTypeDialogOpen] = useState<boolean>(false)
  const theme = useTheme()
  const alertErrorBgColor = lighten(theme.palette.error.light, 0.9)
  const alertWarningBgColor = lighten(theme.palette.warning.light, 0.9)

  const handleRemove = useCallback(() => {
    removeFile(file.name)
  }, [file.name, removeFile])

  const resetUpload = useCallback(() => {
    setUploadProgress(0)
    setUploadStatus("idle")
    setErrorMessage("")
  }, [])

  const isCompleted = useCallback(() => {
    return uploadStatus === "complete"
  }, [uploadStatus])

  const isError = useCallback(() => {
    return uploadStatus === "error" || !!metadataError
  }, [uploadStatus, metadataError])

  const hasMimeType = useCallback(() => {
    return !!file.type
  }, [file.type])

  const handleError = useCallback(
    (errorMsg: string) => {
      setUploadStatus("error")
      setErrorMessage(errorMsg)
      if (onUploadError) {
        onUploadError()
      }
    },
    [onUploadError]
  )

  const handleUploadFile = useCallback(async () => {
    if (!file) return

    if (!file.type) {
      handleError("File missing MIME type. Please set a MIME type before uploading.")
      return
    }

    try {
      // Prevent duplicate uploads
      if (uploadStatus === "uploading" || uploadStatus === "complete") return

      // Reset error state when starting a new upload
      setErrorMessage("")
      setUploadStatus("uploading")
      setUploadProgress(0)

      // Create metadata first and wait for it to complete
      const response = await createFileMetadata({
        datasetNameId: dataset.nameId,
        fileName: slugify(file.name),
        file,
      })

      if (response.error) {
        let errorMsg = "Failed to create file metadata"

        if ("data" in response.error) {
          errorMsg = String(response.error.data) || errorMsg
        } else if ("message" in response.error) {
          errorMsg = response.error.message || errorMsg
        }

        handleError(errorMsg)
        return
      }

      const accessT = await getAccessToken()
      if (!accessT) {
        handleError("No access token found")
        return
      }

      uploadFile({
        datasetNameId: dataset.nameId,
        file,
        authToken: accessT,
        onProgress: percentCompleted => {
          setUploadProgress(percentCompleted)
        },
        onSuccess: () => {
          setUploadStatus("complete")
          onUploadComplete()
        },
        onError: err => {
          handleError(err || "Upload failed")
        },
      })
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : "Metadata creation failed"
      handleError(errorMessage)
    }
  }, [createFileMetadata, dataset.nameId, file, onUploadComplete, uploadStatus, handleError])

  const handleSetMimeType = useCallback(
    (mimeType: string) => {
      // Create a new File object with the provided MIME type
      const updatedFile = new File([file], file.name, { type: mimeType })
      updateFile(file.name, updatedFile)
    },
    [file, updateFile]
  )

  const openMimeTypeDialog = useCallback(() => {
    setMimeTypeDialogOpen(true)
  }, [])

  const closeMimeTypeDialog = useCallback(() => {
    setMimeTypeDialogOpen(false)
  }, [])

  // Expose methods to parent component via ref
  useImperativeHandle(
    ref,
    () => ({
      uploadFile: handleUploadFile,
      resetUpload,
      isCompleted,
      isError,
      hasMimeType,
    }),
    [handleUploadFile, resetUpload, isCompleted, isError, hasMimeType]
  )

  const hasError = isError()
  const isComplete = uploadStatus === "complete"
  const hasMissingMimeType = !file.type

  return (
    <>
      <ListItem
        id={`file-${file.name}`}
        key={file.name + "_file"}
        sx={{
          py: 0.5,
          background: hasError ? alertErrorBgColor : hasMissingMimeType ? alertWarningBgColor : undefined,
        }}>
        <ListItemIcon style={{ minWidth: 40 }}>
          {isComplete ? (
            <CheckCircleIcon color="success" />
          ) : hasError ? (
            <ErrorIcon color="error" />
          ) : hasMissingMimeType ? (
            <WarningIcon color="warning" />
          ) : (
            <ArticleIcon />
          )}
        </ListItemIcon>
        <ListItemText>
          <Typography>file name: {file.name}</Typography>
          <Typography sx={{ fontSize: 14 }}>
            type: {file.type || <span style={{ color: theme.palette.warning.main }}>missing</span>}
          </Typography>
          <Typography sx={{ fontSize: 14 }}>size: {file.size}</Typography>
          {isComplete && <Typography sx={{ fontSize: 14, color: "success.main" }}>Upload complete</Typography>}
          {hasError && <Typography sx={{ fontSize: 14, color: "error.main" }}>Upload failed</Typography>}
          {hasMissingMimeType && !hasError && (
            <Typography sx={{ fontSize: 14, color: "warning.main" }}>
              Missing MIME type
              <StyledButton
                size="small"
                variant="contained"
                onClick={openMimeTypeDialog}
                data-testid="setMimeTypeBtn"
                sx={{ ml: 1 }}>
                Set MIME type
              </StyledButton>
            </Typography>
          )}
        </ListItemText>

        {uploadStatus === "idle" && (
          <>
            <IconButton
              onClick={handleUploadFile}
              data-testid="uploadFileBtn"
              disabled={hasMissingMimeType}
              title={hasMissingMimeType ? "Set MIME type before uploading" : "Upload file"}>
              <UploadFileIcon />
            </IconButton>
            <IconButton onClick={handleRemove} data-testid="discardFileBtn">
              <CloseIcon />
            </IconButton>
          </>
        )}
      </ListItem>
      {hasError && <Alert severity="error">{errorMessage || "Failed to create file metadata"}</Alert>}
      {/* MIME Type Dialog */}
      <MimeTypeDialog
        open={mimeTypeDialogOpen}
        fileName={file.name}
        onClose={closeMimeTypeDialog}
        onSubmit={handleSetMimeType}
      />

      {uploadStatus === "uploading" && <UploadProgress progress={uploadProgress} />}
    </>
  )
})

FileToUpload.displayName = "FileToUploadRaw"

export default React.memo(FileToUpload)
