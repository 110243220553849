import { useCallback, useState } from "react"

export const getStatusText = ({
  filesCount,
  totalProcessed,
  completedCount,
  errorCount,
}: {
  filesCount: number
  totalProcessed: number
  completedCount: number
  errorCount: number
}) => {
  if (filesCount === 0) return ""

  let statusText = totalProcessed > 0 ? `Processed: ${totalProcessed}/${filesCount}` : ""

  if (completedCount > 0) {
    statusText += ` (${completedCount} completed`

    if (errorCount > 0) {
      statusText += `, ${errorCount} failed)`
    } else {
      statusText += ")"
    }
  } else if (errorCount > 0) {
    statusText += ` (${errorCount} failed)`
  }

  return statusText
}

export const useUploaderSnackbar = () => {
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false)
  const [snackbarMessage, setSnackbarMessage] = useState<string>("")
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "info" | "warning" | "error">("info")

  const setSnackbar = useCallback(
    ({ message, severity }: { message: string; severity: "success" | "info" | "warning" | "error" }) => {
      setSnackbarMessage(message)
      setSnackbarSeverity(severity)
      setSnackbarOpen(true)
    },
    []
  )

  const closeSnackbar = useCallback(() => {
    setSnackbarOpen(false)
  }, [])

  return {
    snackbarOpen,
    snackbarMessage,
    snackbarSeverity,
    setSnackbar,
    closeSnackbar,
  }
}
