/* eslint-disable no-unreachable */
import {
  type ApiGetFileBody,
  type ApiUploadFileBody,
  type DeleteRawFileResponse,
  type RawFileFilters,
  type RawFileInfoGet,
  type UploadResponse,
} from "../../api/raw.types"
import { jsSDK } from "../../sdk/jsSDK"
import { type APIResultsRawFiles } from "../../sdk/utils/entities/sdk.dataset.types"
import { datasetsApi } from "./dataset.queries"
import { createResponse } from "./queries.helpers"

export enum RawFilesTags {
  RawFilesInfoList = "RawFilesInfoList",
}

const enhancedApi = datasetsApi.enhanceEndpoints({ addTagTypes: Object.values(RawFilesTags) })

const rawApi = enhancedApi.injectEndpoints({
  endpoints: builder => ({
    getRawFilesInfoForDataset: builder.query<
      APIResultsRawFiles<RawFileInfoGet>,
      { datasetId: string; fileFilters?: RawFileFilters; limit?: number; page?: string }
    >({
      queryFn: async ({ datasetId, fileFilters, limit, page }) => {
        return await createResponse<APIResultsRawFiles<RawFileInfoGet>, APIResultsRawFiles<RawFileInfoGet>>(
          async () => await jsSDK.datasets.getRawFilesInfoForDatasetNameID({ datasetId, fileFilters, limit, page }),
          el => el
        )
      },
      providesTags: (_, __, { datasetId }) => [{ type: RawFilesTags.RawFilesInfoList, id: datasetId }],
    }),

    createRawFileMetadata: builder.mutation<RawFileInfoGet, ApiUploadFileBody>({
      queryFn: async ({ datasetNameId, file, fileName }) => {
        return await createResponse<RawFileInfoGet, RawFileInfoGet>(
          async () =>
            await jsSDK.datasets.createRawFileByDatasetNameID({
              datasetNameId,
              dto: {
                mime_type: file.type,
                name: fileName,
              },
            }),
          el => el
        )
      },
    }),

    uploadRawFileForDatasetByNameId: builder.mutation<UploadResponse | RawFileInfoGet, ApiUploadFileBody>({
      queryFn: async ({ datasetNameId, file, fileName }) => {
        const createdFileMetadataResponse = await createResponse<RawFileInfoGet, RawFileInfoGet>(
          async () =>
            await jsSDK.datasets.createRawFileByDatasetNameID({
              datasetNameId,
              dto: {
                mime_type: file.type,
                name: fileName,
              },
            }),
          el => el
        )

        if ("error" in createdFileMetadataResponse) {
          return createdFileMetadataResponse
        }

        return await createResponse<UploadResponse, UploadResponse>(
          async () => await jsSDK.datasets.uploadRawFileByDatasetNameID({ datasetNameId, file, fileName }),
          el => el
        )
      },
      invalidatesTags: (_, __, arg) => [{ type: RawFilesTags.RawFilesInfoList, id: arg.datasetNameId }],
    }),

    deleteRawFileForDatasetByNameId: builder.mutation<DeleteRawFileResponse, ApiGetFileBody>({
      queryFn: async params => {
        return await createResponse<DeleteRawFileResponse, DeleteRawFileResponse>(
          async () => await jsSDK.datasets.deleteRawFileByDatasetNameID(params),
          el => el
        )
      },
      invalidatesTags: (_, __, arg) => [{ type: RawFilesTags.RawFilesInfoList, id: arg.datasetNameId }],
    }),
  }),
})

export const {
  useGetRawFilesInfoForDatasetQuery,
  useUploadRawFileForDatasetByNameIdMutation,
  useDeleteRawFileForDatasetByNameIdMutation,
  useCreateRawFileMetadataMutation,
} = rawApi
