import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"

import {
  Box,
  Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material"
import { ClearIcon } from "@mui/x-date-pickers"
import React, { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import LabeledIconButton from "../../../../../../../../../../../components/CollectionDatasets/LabeledIconButton"
import { Loader } from "../../../../../../../../../../../components/Loader/Loader"
import { colorPalette } from "../../../../../../../../../../../styling/theme"
import {
  AccessRoleId,
  UserType,
} from "../../../../../../../../../../../utils/api/AccessManagement/resourceAccess.types"
import { useAddResourceUserMutation } from "../../../../../../../../../../../utils/redux/queries/access.queries"
import { setAccessLevelErrorRA } from "../../../../../../../../../../../utils/redux/reducers/datasetsReducer"
import { useAppDispatch } from "../../../../../../../../../../../utils/redux/store"
import { EMAIL_REGEX } from "../../../../../../../../../../../utils/sdk/helpers"
import UserAccessGroups from "./UserAccessGroups"

type Props = {
  roleId: AccessRoleId
  addButtonText: string
  resourceUuid: string
  groupIds: string[]
}

const AddingUser = (p: Props) => {
  const [addUser, { isLoading, error }] = useAddResourceUserMutation()
  const dispatch = useAppDispatch()
  const [radioAddSubject, setRadioAddSubject] = useState(UserType.user)

  const [showAddReaderInput, setShowAddReaderInput] = useState(false)
  const form = useForm({ defaultValues: { userEmail: "" } })

  const addUserSubmit = async () => {
    await addUser({
      resourceId: p.resourceUuid,
      userEmail: form.getValues("userEmail"),
      roleId: p.roleId,
      userType: UserType.user,
    })
  }

  const clickClear = () => {
    setShowAddReaderInput(false)
  }

  useEffect(() => {
    dispatch(setAccessLevelErrorRA(error))
  }, [error, dispatch])

  useEffect(() => {
    if (!showAddReaderInput) form.reset()
  }, [showAddReaderInput, form])

  useEffect(() => {
    if (!showAddReaderInput) form.reset()
  }, [showAddReaderInput, form])

  const roleName = (roleId: string): string => {
    if (roleId === AccessRoleId.Admin) {
      return "Admins"
    } else if (roleId === AccessRoleId.Editor) {
      return "Editors"
    } else {
      return "Viewers"
    }
  }

  const ContributorRoleName = roleName(p.roleId)
  const groupIds = p.groupIds

  const accessGroups = () => {
    if (p.roleId !== AccessRoleId.Admin) {
      return (
        <UserAccessGroups resourceId={p.resourceUuid} roleName={ContributorRoleName} contributorGroups={groupIds} />
      )
    }
  }

  const handleAddSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioAddSubject(event.target.value as UserType)
  }

  return (
    <>
      {isLoading && (
        <Box width={"100%"} display="flex" justifyContent={"center"} sx={{ mt: 2 }}>
          <Loader size={25} />
        </Box>
      )}
      {!isLoading && (
        <>
          {!showAddReaderInput && (
            <LabeledIconButton
              icon={<AddCircleOutlineIcon />}
              label={p.addButtonText}
              onClick={() => setShowAddReaderInput(true)}
              sx={{ mt: 2 }}
            />
          )}
          {showAddReaderInput && (
            <>
              <Divider sx={{ pt: 1 }}>Add {ContributorRoleName}</Divider>
              <Stack direction="row" sx={{ pl: 0.5, pr: 0.5, justifyContent: "space-between" }}>
                <Stack>
                  <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                    <FormControlLabel
                      control={
                        <Radio
                          value={UserType.user}
                          checked={radioAddSubject === UserType.user}
                          onChange={handleAddSubjectChange}
                          size="small"
                        />
                      }
                      label="User"
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          value={UserType.group}
                          checked={radioAddSubject === UserType.group}
                          onChange={handleAddSubjectChange}
                          size="small"
                        />
                      }
                      label="Group"
                    />
                  </RadioGroup>
                </Stack>
                <Stack>
                  <IconButton onClick={clickClear} size="small">
                    <ClearIcon sx={{ color: colorPalette.deepPurpleO20 }} />
                  </IconButton>
                </Stack>
              </Stack>
            </>
          )}
          {radioAddSubject === UserType.user && showAddReaderInput && (
            <form onSubmit={form.handleSubmit(addUserSubmit)}>
              <Controller
                name={"userEmail"}
                control={form.control}
                rules={{ pattern: { value: EMAIL_REGEX, message: "Invalid email address" } }}
                render={({ field, formState }) => {
                  return (
                    <TextField
                      {...field}
                      label="Email address"
                      value={field.value || ""}
                      error={!!formState.errors.userEmail}
                      helperText={formState.errors.userEmail?.message?.toString()}
                      placeholder={"example@mail.com"}
                      size={"small"}
                      sx={{ mt: 1 }}
                      fullWidth
                      InputProps={{
                        sx: { pr: 0.5 },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton type="submit" size="small">
                              <AddCircleOutlineIcon sx={{ color: colorPalette.deepPurpleO20 }} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )
                }}
              />
            </form>
          )}
          {radioAddSubject === UserType.group && showAddReaderInput && accessGroups()}
        </>
      )}
    </>
  )
}

export default React.memo(AddingUser)
