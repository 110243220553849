import { Alert, Grid } from "@mui/material"
import React from "react"
import { ErrorBoundary } from "react-error-boundary"
import type { DataSet } from "../../../../utils/frontendTypes/datasets.types"
import DatasetFacets from "./DatasetFacets"
import DatasetLinks from "./DatasetLinks/DatasetLinks"
import DatasetMetadata from "./DatasetMetadata"

type Props = {
  dataset: DataSet
}

const MyDatasetMetadata = (p: Props) => {
  return (
    <>
      <Grid item>
        <ErrorBoundary fallbackRender={() => <Alert severity="error">{"Something went wrong"}</Alert>}>
          <DatasetMetadata dataset={p.dataset} />
        </ErrorBoundary>
      </Grid>
      <Grid item>
        <ErrorBoundary fallbackRender={() => <Alert severity="error">{"Something went wrong"}</Alert>}>
          <DatasetLinks dataset={p.dataset} />
        </ErrorBoundary>
      </Grid>
      <Grid item>
        <ErrorBoundary fallbackRender={() => <Alert severity="error">{"Something went wrong"}</Alert>}>
          <DatasetFacets dataset={p.dataset} />
        </ErrorBoundary>
      </Grid>
    </>
  )
}

export default React.memo(MyDatasetMetadata)
