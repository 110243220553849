import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material"
import React, { useState } from "react"
import { StyledButton } from "../../../../../../../../../components/StyledButton"

type Props = {
  open: boolean
  fileName: string
  onClose: () => void
  onSubmit: (mimeType: string) => void
}

const MimeTypeDialog: React.FC<Props> = ({ open, fileName, onClose, onSubmit }) => {
  const [mimeType, setMimeType] = useState<string>("")
  const [error, setError] = useState<string>("")

  const handleSubmit = () => {
    if (!mimeType.trim()) {
      setError("MIME type cannot be empty")
      return
    }

    // Basic validation for MIME type format
    if (!mimeType.includes("/")) {
      setError("MIME type should be in format 'type/subtype'")
      return
    }

    onSubmit(mimeType.trim())
    setMimeType("")
    setError("")
    onClose()
  }

  const handleCancel = () => {
    setMimeType("")
    setError("")
    onClose()
  }

  // Common MIME types suggestions
  const commonMimeTypes = [
    { type: "application/x-rar-compressed", description: "RAR file" },
    { type: "application/json", description: "JSON file" },
    { type: "application/pdf", description: "PDF document" },
    { type: "application/vnd.ms-excel", description: "Excel file" },
    { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", description: "Excel XLSX file" },
    { type: "text/plain", description: "Plain text file" },
    { type: "application/xml", description: "XML file" },
    { type: "application/zip", description: "ZIP archive" },
    { type: "image/jpeg", description: "JPEG image" },
    { type: "image/png", description: "PNG image" },
  ]

  const handleSuggestionClick = (suggestion: string) => {
    setMimeType(suggestion)
    setError("")
  }

  return (
    <Dialog open={open} onClose={handleCancel} maxWidth="sm" fullWidth PaperProps={{ sx: { p: 3 } }}>
      <DialogTitle>Set MIME Type</DialogTitle>
      <DialogContent>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
          The file <strong>{fileName}</strong> is missing a MIME type, which is required for upload. Please specify the
          correct MIME type.
        </Typography>

        <TextField
          fullWidth
          label="MIME Type"
          size="small"
          value={mimeType}
          onChange={e => setMimeType(e.target.value)}
          error={!!error}
          placeholder="E.g., application/pdf, text/csv"
          autoFocus
          sx={{ mb: 1 }}
        />
        {error && <FormHelperText error>{error}</FormHelperText>}

        <Box sx={{ mt: 3 }}>
          <Typography variant="subtitle2" gutterBottom>
            Common MIME types:
          </Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 1 }}>
            {commonMimeTypes.map(item => (
              <StyledButton
                key={item.type}
                size="small"
                variant="outlined"
                onClick={() => handleSuggestionClick(item.type)}
                sx={{ textTransform: "none" }}>
                {item.type}
              </StyledButton>
            ))}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <StyledButton onClick={handleCancel}>Cancel</StyledButton>
        <StyledButton onClick={handleSubmit} variant="contained" disabled={!mimeType.trim()}>
          Apply MIME Type
        </StyledButton>
      </DialogActions>
    </Dialog>
  )
}

export default MimeTypeDialog
