import { getAccessToken, msalInstance } from "../../auth/msalConfig"
import type { APIResultsDto } from "../../sdk/utils/entities/sdk.dataset.types"
import { ResourceKind } from "../../sdk/utils/entities/sdk.resource.types"
import type { getTokenFnType, requestFnType } from "../../sdk/utils/requests/sdk.request.types"
import { requestFn } from "../../sdk/utils/requests/sdk.requests"
import type { ApiResponse } from "../response.types"
import { createDeleteSubjectDto, createSubjectDto } from "./resourceAccess.helpers"
import {
  type AccessGroup,
  type AccessRequest,
  type AccessRequestPermissions,
  type AccessRequestStatus,
  type AccessRoleId,
  type PermissionApi,
  type ResourceAccess,
  type UserIdentifier,
  type UserType,
} from "./resourceAccess.types"

class ResourceAccessManager {
  requestFn: requestFnType
  userId?: string

  constructor(getTokenFn: getTokenFnType) {
    this.requestFn = async (endpoint, requestOptions?) => await requestFn(endpoint, getTokenFn, requestOptions)
  }

  async getUserId(): Promise<string> {
    if (this.userId) return this.userId

    const userId = msalInstance.getActiveAccount()?.localAccountId

    if (userId) {
      this.userId = userId
      return userId
    }

    if (!userId) {
      if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
        msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
      }
      const fetchedId = msalInstance.getActiveAccount()?.localAccountId
      if (fetchedId) {
        this.userId = fetchedId
        return fetchedId
      }
    }

    throw new Error("User id not found")
  }

  async getAccessLevelForResource(resourceId: string): Promise<ApiResponse<ResourceAccess[]>> {
    return await this.requestFn(
      `api/odcat-services/v1/resources/relationships/?object=${resourceId}&subject=${await this.getUserId()}`
    )
  }

  async getDatasetUsers(resourceId: string): Promise<ApiResponse<ResourceAccess[]>> {
    return await this.requestFn(`api/odcat-services/v1/resources/relationships/?object=${resourceId}`)
  }

  async addDatasetSubject(
    params: {
      resourceId: string
      roleId: AccessRoleId
      userType: UserType
    } & UserIdentifier
  ): Promise<ApiResponse<ResourceAccess>> {
    return await this.requestFn("api/odcat-services/v1/resources/relationships/", {
      method: "POST",
      body: createSubjectDto(params),
    })
  }

  async removeDatasetSubject({
    resourceId,
    subjectId,
    roleId,
    subjectType,
  }: {
    resourceId: string
    subjectId: string
    roleId: AccessRoleId
    subjectType: UserType
  }): Promise<ApiResponse<ResourceAccess>> {
    return await this.requestFn("api/odcat-services/v1/resources/relationships/", {
      method: "DELETE",
      body: createDeleteSubjectDto({ subjectId, roleId, resourceId, subjectType }),
    })
  }

  async getMyAccessGroups(): Promise<ApiResponse<AccessGroup[]>> {
    return await this.requestFn("/api/permissions/v1/groups/access/", {
      method: "GET",
    })
  }

  async getSharedResources<T>(kind: ResourceKind, parrentUuid?: string): Promise<ApiResponse<APIResultsDto<T>>> {
    return await this.requestFn(
      `api/odcat-services/v1/resources/?subject=${await this.getUserId()}&includeOwner=false&kind=${encodeURIComponent(
        kind
      )}`
    )
  }

  async getSharedDatasetsInCollection<T>(parrentUuid?: string): Promise<ApiResponse<APIResultsDto<T>>> {
    return await this.requestFn(
      `api/odcat-services/v1/resources/?kind=${encodeURIComponent(ResourceKind.dataset)}${
        parrentUuid ? `&parentUuid=${parrentUuid}` : ""
      }`
    )
  }

  async getSharedCollections<DataCollectionDtoGet>() {
    return await this.getSharedResources<DataCollectionDtoGet>(ResourceKind.collection)
  }

  async getSharedDatasets<DatasetDtoGet>(parrentUuid?: string): Promise<ApiResponse<APIResultsDto<DatasetDtoGet>>> {
    return await this.getSharedResources<DatasetDtoGet>(ResourceKind.dataset, parrentUuid)
  }

  async getWaitlistedUsers(status: AccessRequestStatus): Promise<ApiResponse<PermissionApi[]>> {
    return await this.requestFn(`api/permissions/v1/users/request/?status=${status}`)
  }

  async setAccessRequestStatus({
    permissionId,
    status,
    permissions,
    reason,
  }: {
    permissionId: string
    status: AccessRequestStatus
    permissions?: AccessRequestPermissions
    reason?: string
  }): Promise<ApiResponse<{ status: string }>> {
    return await this.requestFn(`api/permissions/v1/users/request/${permissionId}/status/`, {
      method: "PUT",
      body: { status, permissions, reason },
    })
  }

  async acceptRejectedRequest({
    permissionId,
    status,
    permissions,
    reason,
  }: {
    permissionId: string
    status: AccessRequestStatus
    permissions?: AccessRequestPermissions
    reason?: string
  }): Promise<ApiResponse<{ status: string }>> {
    return await this.requestFn(`api/permissions/v1/users/request/${permissionId}/status/rejected/`, {
      method: "PUT",
      body: { status, permissions, reason },
    })
  }

  async getUserAccessRequests(): Promise<ApiResponse<AccessRequest[]>> {
    return await this.requestFn(`api/permissions/v1/users/${await this.getUserId()}/`)
  }

  async checkUserAdmin(): Promise<
    ApiResponse<{
      object_id: string
      is_whitelist_admin: boolean
    }>
  > {
    return await this.requestFn("api/permissions/v1/users/am-i-whitelist-admin/")
  }
}

export const accessManager = new ResourceAccessManager(getAccessToken)
