import { getNameIdFromReferenceId } from "../../../pages/Catalog/catalog.helpers"
import type { DataCollection } from "../../frontendTypes/dataCollection.types"
import type { UIResults } from "../../frontendTypes/dataEntity.types"
import { type DataSet } from "../../frontendTypes/datasets.types"
import {
  mapCollectionResults,
  mapToDataCollection,
  mapToDataset,
  mapToDatasetResults,
} from "../../frontendTypes/frontendMappers"
import { jsSDK } from "../../sdk/jsSDK"
import { type DataCollectionDtoGet } from "../../sdk/utils/entities/sdk.dataCollection.types"
import { type APIResultsDto, type DatasetDtoGet } from "../../sdk/utils/entities/sdk.dataset.types"
import { ResourceKind, type JsonLdDocument, type ResourceRequestParams, type ResourceTypeOdp } from "../../sdk/utils/entities/sdk.resource.types"
import { DatasetApiTags, datasetsApi } from "./dataset.queries"
import { createResponse } from "./queries.helpers"

export enum CatalogTags {
  AllCatalogEntries = "AllCatalogEntries",
  DatasetResults = "DatasetResults",
  DataCollectionResults = "DataCollectionResults",

  DatasetOpen = "DatasetsOpen",
  CollectionOpen = "CollectionsOpen",
}

const catalogApi = datasetsApi.enhanceEndpoints({ addTagTypes: Object.values(CatalogTags) })

const metadataApi = catalogApi.injectEndpoints({
  endpoints: builder => ({
    getMetadataById: builder.query<DatasetDtoGet, string>({
      queryFn: async datasetID => {
        return await createResponse<DatasetDtoGet, DatasetDtoGet>(
          async () => await jsSDK.datasets.getDatasetByIdApi(datasetID),
          el => el
        )
      },
      providesTags: [DatasetApiTags.UserDatasets],
    }),

    getMetadataByNameId: builder.query<DataSet, { nameId: string; isAnonymous?: boolean }>({
      queryFn: async ({ nameId, isAnonymous }) => {
        return await createResponse<DatasetDtoGet, DataSet>(async () => {
          if (isAnonymous) return await jsSDK.catalog.getDatasetByIdOpen(nameId)
          return await jsSDK.datasets.getDatasetByNameApi(nameId)
        }, mapToDataset)
      },
      providesTags: [DatasetApiTags.UserDatasets],
    }),

    getCollectionMetadataByNameId: builder.query<DataCollection, { nameId: string; isAnonymous?: boolean }>({
      queryFn: async ({ nameId, isAnonymous }) => {
        return await createResponse<DataCollectionDtoGet, DataCollection>(
          async () => {
            if (isAnonymous) return await jsSDK.catalog.getCollectionByIdOpen(nameId)
            return await jsSDK.datasets.getDataCollectionByNameApi(nameId)
          },
          el => mapToDataCollection(el)
        )
      },
      providesTags: [DatasetApiTags.UserDataCollections],
    }),

    getDatasetSearchResults: builder.query<
      UIResults<DataSet>,
      ResourceRequestParams & { collectionId?: string; isAnonymous?: boolean }
    >({
      queryFn: async params => {
        const nameIds = params.filterIds?.map(getNameIdFromReferenceId) as string[]
        return await createResponse<APIResultsDto<DatasetDtoGet>, UIResults<DataSet>>(async () => {
          if (params.isAnonymous) return await jsSDK.catalog.getDatasetSearchResultOpen(params)

          return await jsSDK.catalog.getDatasetSearchResult({
            ...params,
            filterIds: nameIds,
          })
        }, mapToDatasetResults)
      },
      providesTags: [CatalogTags.DatasetResults],
    }),

    getDataCollectionSearchResults: builder.query<
      UIResults<DataCollection>,
      ResourceRequestParams & {
        isAnonymous?: boolean
      }
    >({
      queryFn: async params => {
        const nameIds = params.filterIds?.map(getNameIdFromReferenceId) as string[]
        return await createResponse<APIResultsDto<DataCollectionDtoGet>, UIResults<DataCollection>>(
          async () => {
            if (params.isAnonymous) {
              return await jsSDK.catalog.getCollectionsSearchResultOpen(params)
            }
            return await jsSDK.catalog.getResourceSearchResults<DataCollectionDtoGet>({
              ...params,
              filterIds: nameIds,
              kind: ResourceKind.collection,
              resourceAvailability: params.isPublic ? "public" : "private",
            })
          },

          mapCollectionResults
        )
      },
      providesTags: [CatalogTags.DataCollectionResults],
    }),

    getJsonLdByNameId: builder.query<
      JsonLdDocument,
      { resourceType: ResourceTypeOdp; nameId: string }
    >({
      queryFn: async (args) =>
        await createResponse<JsonLdDocument, JsonLdDocument>(
          async () => await jsSDK.catalog.getJsonLdByNameIdOpen(args),
          el => el
        ),
    }),
  }),
})

export const {
  useGetMetadataByIdQuery,
  useGetDatasetSearchResultsQuery,
  useGetMetadataByNameIdQuery,
  useGetCollectionMetadataByNameIdQuery,
  useGetDataCollectionSearchResultsQuery,
  useGetJsonLdByNameIdQuery,
} = metadataApi
