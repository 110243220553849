import { Box, Typography } from "@mui/material"
import React from "react"
import { Link } from "react-router-dom"
import { Loader } from "../../../../../components/Loader/Loader"
import { paths } from "../../../../../components/navigation.helpers"
import { colorPalette } from "../../../../../styling/theme"
import { useCollections } from "../../../../../utils/hooks/resourceHooks"

const WidgetDataCollections = () => {
  const { collectionResults, isFetching } = useCollections({ isPublic: false, isInternallyShared: false })

  const collections = collectionResults?.results

  const showMoreButton = !!collections?.length && collections.length > 5

  return (
    <Box sx={{ mb: 2 }}>
      {!!collections &&
        collections.slice(0, 5).map(ds => (
          <Typography
            key={"widget_list_item" + ds.nameId}
            component={Link}
            to={`${paths["My Data"]}/${ds.nameId}`}
            sx={{
              color: colorPalette.luminousPurple,
              textDecoration: "none",
              display: "block",
              lineHeight: "2em",
              wordBreak: "break-word",
            }}>
            {ds.name}
          </Typography>
        ))}
      {showMoreButton && (
        <Typography
          component={Link}
          to={`${paths["My Data"]}/`}
          sx={{
            color: colorPalette.luminousPurple,
            textDecoration: "none",
            display: "block",
            lineHeight: "2em",
            fontWeight: "bold ",
          }}>
          {"Show all data collections"}
        </Typography>
      )}
      {isFetching && (
        <Box display={"flex"} justifyContent={"center"} sx={{ mt: 2, mb: 2 }}>
          <Loader size={25} color={colorPalette.deepPurpleO30} />
        </Box>
      )}
    </Box>
  )
}

export default React.memo(WidgetDataCollections)
