import React from "react"
import { useGetJsonLdByNameIdQuery } from "../utils/redux/queries/catalog.queries"
import type { ResourceTypeOdp } from "../utils/sdk/utils/entities/sdk.resource.types"

interface JsonLdProps {
  nameId: string
  resourceType: ResourceTypeOdp
}

const JsonLd: React.FC<JsonLdProps> = ({ resourceType, nameId }) => {
  const { data: jsonldData, isLoading, error } = useGetJsonLdByNameIdQuery({ resourceType, nameId })

  if (isLoading || error || !jsonldData) return null

  return (
    <script type="application/ld+json">
      {JSON.stringify(jsonldData)}
    </script>
  )
}

export default React.memo(JsonLd)
