// FileUploader.tsx
import { AddCircleOutline } from "@mui/icons-material"
import CloseIcon from "@mui/icons-material/Close"
import UploadFileIcon from "@mui/icons-material/UploadFile"
import { Alert, Box, IconButton, Snackbar, Typography } from "@mui/material"
import React, { useEffect, useRef, useState, type ChangeEvent } from "react"
import LabeledIconButton from "../../../../../../../../components/CollectionDatasets/LabeledIconButton"
import { StyledSectionHeader } from "../../../../../../../../components/StyledTypography"
import type { DataSet } from "../../../../../../../../utils/frontendTypes/datasets.types"
import { datasetsApi, type DatasetApiTags } from "../../../../../../../../utils/redux/queries/dataset.queries"
import { RawFilesTags } from "../../../../../../../../utils/redux/queries/raw.queries"
import { useAppDispatch } from "../../../../../../../../utils/redux/store"
import { prepareOpenedFile } from "../rawFile.helpers"
import FileDropzone from "./components/FileDropzone"
import { S } from "./components/FileDropzone.style"
import type { FileUploadRef } from "./components/FileToUpload"
import FileToUpload from "./components/FileToUpload"
import { getStatusText, useUploaderSnackbar } from "./uploader.helpers"
import { useDragDropFiles } from "./useDragDropFiles"

interface FileUploaderProps {
  dataset: DataSet
}

const FileUploader: React.FC<FileUploaderProps> = ({ dataset }) => {
  const [files, setFiles] = useState<File[]>([])
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [completedCount, setCompletedCount] = useState<number>(0)
  const [errorCount, setErrorCount] = useState<number>(0)
  const inputRef = useRef<HTMLInputElement>(null)
  const dispatch = useAppDispatch()

  const { snackbarOpen, snackbarMessage, snackbarSeverity, setSnackbar, closeSnackbar } = useUploaderSnackbar()

  // Create and manage refs for file upload components
  const fileUploadRefs = useRef<Record<string, React.RefObject<FileUploadRef>>>({})

  // Effect to handle upload completion (both success and error cases)
  useEffect(() => {
    const allFilesProcessed = files.length > 0 && completedCount + errorCount === files.length
    if (allFilesProcessed) {
      setIsUploading(false)

      if (errorCount > 0) {
        const message = `Upload finished: ${completedCount} successful, ${errorCount} failed`
        setSnackbar({ message, severity: "warning" })
      } else {
        const message = `Successfully uploaded ${completedCount} ${completedCount === 1 ? "file" : "files"}`
        setSnackbar({ message, severity: "success" })

        // Refresh uploaded files
        dispatch(
          datasetsApi.util.invalidateTags([
            { type: RawFilesTags.RawFilesInfoList as unknown as DatasetApiTags, id: dataset.nameId },
          ])
        )
      }
    }
  }, [files, completedCount, errorCount, isUploading, dispatch, dataset.nameId, setSnackbar])

  const handleFilesSelection = (newFiles: FileList) => {
    const filesArray = Array.from(newFiles).map(file => prepareOpenedFile(file))

    // Filter out files that already exist in the current state
    const existingFileNames = new Set(files.map(file => file.name))
    const uniqueNewFiles = filesArray.filter(file => !existingFileNames.has(file.name))
    if (uniqueNewFiles.length > 0) setFiles(prevFiles => [...prevFiles, ...uniqueNewFiles])

    // Provide feedback if duplicates were found
    if (uniqueNewFiles.length < filesArray.length) {
      const duplicateCount = filesArray.length - uniqueNewFiles.length
      const message = `${duplicateCount} duplicate ${duplicateCount === 1 ? "file was" : "files were"} not added`
      setSnackbar({ message, severity: "info" })
    }

    // Check for missing MIME types and notify user
    const missingMimeTypes = uniqueNewFiles.filter(file => !file.type).length
    if (missingMimeTypes > 0) {
      const message = `${missingMimeTypes} ${
        missingMimeTypes === 1 ? "file has" : "files have"
      } missing MIME type and need to be fixed before uploading`
      setSnackbar({ message, severity: "warning" })
    }
  }

  const { dragActive, handleDrag, handleDrop } = useDragDropFiles({ handleFiles: handleFilesSelection })

  const openSelectFilesDialog = () => {
    if (inputRef.current) {
      inputRef.current.value = ""
      inputRef.current.click()
    }
  }

  const removeFile = (fileName: string) => {
    setFiles(prevFiles => prevFiles.filter(file => file.name !== fileName))
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete fileUploadRefs.current[fileName]
  }

  const updateFile = (fileName: string, updatedFile: File) => {
    setFiles(prevFiles => prevFiles.map(file => (file.name === fileName ? updatedFile : file)))
  }

  const handleUploadComplete = () => setCompletedCount(prev => prev + 1)
  const handleUploadError = () => setErrorCount(prev => prev + 1)

  const uploadAllFiles = async () => {
    setIsUploading(true)
    // Reset counters before starting new upload batch
    setCompletedCount(0)
    setErrorCount(0)

    // First, ensure we have refs for all files
    const missingRefs = files.filter(
      // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
      file => !fileUploadRefs.current[file.name] || !fileUploadRefs.current[file.name].current
    )

    if (missingRefs.length > 0) {
      missingRefs.forEach(file => {
        fileUploadRefs.current[file.name] = React.createRef<FileUploadRef>()
      })

      // Force a re-render to ensure refs are connected
      setFiles([...files])
      return
    }

    // Trigger upload for all files
    for (const [fileName, ref] of Object.entries(fileUploadRefs.current)) {
      if (ref.current) {
        try {
          await ref.current.uploadFile()
        } catch (error) {
          // Error handling is now done in the FileToUpload component
          console.error(`Error uploading file ${fileName}:`, error)
        }
      }
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    if (e.target.files && e.target.files.length > 0) {
      handleFilesSelection(e.target.files)
    }
  }

  const clearFiles = () => {
    // Reset the file input value
    if (inputRef.current) {
      inputRef.current.value = ""
    }

    setFiles([])
    setCompletedCount(0)
    setErrorCount(0)
    fileUploadRefs.current = {}
  }

  useEffect(() => {
    if (files.length === 0) {
      setIsUploading(false)
      setCompletedCount(0)
      setErrorCount(0)
    }
  }, [files])

  const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") return
    closeSnackbar()
  }

  const totalProcessed = completedCount + errorCount
  const allUploadsFinished = totalProcessed === files.length
  const hasMissingMimeTypes = files.some(file => !file.type)

  const statusText = getStatusText({
    filesCount: files.length,
    totalProcessed,
    completedCount,
    errorCount,
  })

  const allowUpload = !isUploading && !allUploadsFinished && !hasMissingMimeTypes

  const showDropzone = !isUploading && files.length === 0

  const fileListEvents = allowUpload
    ? {
        onDragEnter: handleDrag,
        onDragOver: handleDrag,
        onDragLeave: handleDrag,
        onDrop: handleDrop,
      }
    : {}

  return (
    <Box>
      <input ref={inputRef} type="file" multiple onChange={handleChange} style={{ display: "none" }} />

      {showDropzone && (
        <Box>
          <StyledSectionHeader sx={{ mb: 1 }}>Upload files</StyledSectionHeader>
          <FileDropzone handleFiles={handleFilesSelection} onButtonClick={openSelectFilesDialog} />
        </Box>
      )}

      {files.length > 0 && (
        <Box {...fileListEvents}>
          <Box>
            <Box display={"flex"} alignItems={"center"}>
              <StyledSectionHeader>Selected Files ({files.length})</StyledSectionHeader>
              {!isUploading && (
                <>
                  {!allUploadsFinished && (
                    <IconButton onClick={openSelectFilesDialog} sx={{ mr: "auto", ml: 0.5 }}>
                      <AddCircleOutline sx={{ fontSize: 20 }} />
                    </IconButton>
                  )}
                  <Box marginLeft={"auto"}>
                    {!allUploadsFinished && (
                      <LabeledIconButton
                        icon={<UploadFileIcon />}
                        label={"Upload All"}
                        onClick={uploadAllFiles}
                        buttonProps={{
                          disabled: isUploading || hasMissingMimeTypes,
                          title: hasMissingMimeTypes ? "Fix missing MIME types before uploading" : undefined,
                        }}
                      />
                    )}
                    <LabeledIconButton
                      icon={<CloseIcon />}
                      label={"Clear All"}
                      onClick={clearFiles}
                      buttonProps={{
                        disabled: isUploading && !allUploadsFinished,
                      }}
                    />
                  </Box>
                </>
              )}
            </Box>
            <Typography variant="subtitle2" sx={{ mb: 0.5, mt: 0 }}>
              {statusText}
              {hasMissingMimeTypes && !isUploading && (
                <Typography component="span" variant="subtitle2" color="warning.main" sx={{ ml: 1 }}>
                  • {files.filter(file => !file.type).length} files missing MIME type
                </Typography>
              )}
            </Typography>
          </Box>
          <S.FileList sx={{ mb: 3 }} maxHeight={250} overflow={"auto"} className={dragActive ? "active" : ""}>
            {files.map((file, index) => {
              // Ensure we have a ref for this file
              if (!fileUploadRefs.current[file.name]) {
                fileUploadRefs.current[file.name] = React.createRef<FileUploadRef>()
              }

              return (
                <FileToUpload
                  key={`${file.name}-${index}`}
                  ref={fileUploadRefs.current[file.name]}
                  dataset={dataset}
                  file={file}
                  onUploadComplete={handleUploadComplete}
                  onUploadError={handleUploadError}
                  removeFile={removeFile}
                  updateFile={updateFile}
                />
              )
            })}
          </S.FileList>
        </Box>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default FileUploader
