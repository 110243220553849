import { Box, Typography } from "@mui/material"
import { StyledButton } from "../../../../../../../../../components/StyledButton"
import { colorPalette } from "../../../../../../../../../styling/theme"
import { useDragDropFiles } from "../useDragDropFiles"
import { S } from "./FileDropzone.style"

interface FileDropzoneProps {
  handleFiles: (files: FileList) => void
  onButtonClick: () => void
}

const FileDropzone = ({ handleFiles, onButtonClick }: FileDropzoneProps) => {
  const { dragActive, handleDrag, handleDrop } = useDragDropFiles({ handleFiles })

  return (
    <S.FileDropzone
      className={`${dragActive ? "active" : ""}`}
      onDragEnter={handleDrag}
      onDragOver={handleDrag}
      onDragLeave={handleDrag}
      onDrop={handleDrop}>
      <Box color={colorPalette.deepPurpleO60}>
        <Typography sx={{ mb: 0.5 }}>Drag & drop files here or</Typography>
        <StyledButton variant="contained" onClick={onButtonClick}>
          Select Files
        </StyledButton>
      </Box>
    </S.FileDropzone>
  )
}

export default FileDropzone
