import { Box, Link, Typography, type SxProps } from "@mui/material"
import React from "react"
import { isUrl } from "../../../utils/sdk/helpers"
import ShowMoreText from "../../ShowMoreText/ShowMoreText"
import ValueWithCopyButton from "../../ValueWithCopyButton"

type Props = {
  value: unknown
  sx?: SxProps
  enableCopying?: boolean
  stringMaxLength?: number
}

const MetadataValue = (p: Props) => {
  const isString = typeof p.value === "string"

  if (isString) {
    const isValueUrl = isUrl(p.value as string)

    if (isValueUrl) {
      return (
        <Link href={p.value as string} rel="noopener noreferrer" target="_blank" sx={{ wordBreak: "break-all" }}>
          {p.value as string}
        </Link>
      )
    }
  }

  if (p.enableCopying && typeof p.value === "string") return <ValueWithCopyButton value={p.value} />

  const isArray = Array.isArray(p.value)
  if (isArray) {
    return (
      <Box>
        {(p.value as any[]).map((el, i) => (
          <Box key={"met_val_" + i + el}>
            <MetadataValue value={el} />
          </Box>
        ))}
      </Box>
    )
  }

  const valueStringified = p.value instanceof Object ? JSON.stringify(p.value) : p.value?.toString()

  return (
    <Typography sx={{ fontSize: "inherit", wordBreak: "break-all", ...p.sx }}>
      {typeof p.value === "string" ? <ShowMoreText maxLength={p.stringMaxLength} text={p.value} /> : valueStringified}
    </Typography>
  )
}

export default React.memo(MetadataValue)
