import { slugify } from "../../../../../../../utils/api/requests.utils"
import { ResourceKind } from "../../../../../../../utils/sdk/utils/entities/sdk.resource.types"
import { getApiUrl } from "../../../../../../../utils/sdk/utils/requests/sdk.request.helpers"

// fileUploadService.ts
interface UploadOptions {
  file: File
  datasetNameId: string
  authToken: string
  onProgress: (percentage: number) => void
  onSuccess: (response: any) => void
  onError: (error: string) => void
}

interface UploadController {
  abort: () => void
}

/**
 * Uploads a file with progress tracking
 * Returns a controller to abort the upload if needed
 */
export const uploadFile = (options: UploadOptions): UploadController => {
  const { file, datasetNameId, authToken, onProgress, onSuccess, onError } = options

  const url = getApiUrl(`data/${ResourceKind.dataset}/${datasetNameId}/${slugify(file.name)}`)
  const xhr = new XMLHttpRequest()

  // Set up progress tracking
  xhr.upload.addEventListener("progress", (event: ProgressEvent<EventTarget>) => {
    if (event.lengthComputable) {
      const percentage = Math.round((event.loaded / event.total) * 100)
      onProgress(percentage)
    }
  })

  // Handle successful response
  xhr.addEventListener("load", () => {
    if (xhr.status >= 200 && xhr.status < 300) {
      try {
        const response = xhr.response ? JSON.parse(xhr.response) : {}
        onSuccess(response)
      } catch (e) {
        // Response is not JSON
        onSuccess(xhr.response)
      }
    } else {
      try {
        const response = xhr.response ? JSON.parse(xhr.response) : {}
        onError(`Upload failed: ${response.error}`)
      } catch (e) {
        onError(`Upload failed: ${xhr.statusText || xhr.status}`)
      }
    }
  })

  xhr.addEventListener("error", () => {
    onError("An error occurred during upload")
  })

  xhr.addEventListener("abort", () => {
    onError("Upload was aborted")
  })

  // Read file as ArrayBuffer and upload
  const reader = new FileReader()

  reader.onload = event => {
    if (event.target?.result) {
      xhr.open("PATCH", url, true)
      xhr.setRequestHeader("Content-Type", "application/octet-stream")
      xhr.setRequestHeader("Authorization", `Bearer ${authToken}`)
      xhr.send(event.target.result)
    } else {
      onError("File reading resulted in null data")
    }
  }

  reader.onerror = () => {
    onError("Error reading the file")
  }

  reader.readAsArrayBuffer(file)

  // Return controller to abort upload if needed
  return {
    abort: () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) {
        xhr.abort()
      }
    },
  }
}
