import { Box, Typography } from "@mui/material"
import React from "react"
import { Loader } from "../../../../../components/Loader/Loader"
import { colorPalette } from "../../../../../styling/theme"
import type { DataSet } from "../../../../../utils/frontendTypes/datasets.types"
import { useGetDataAnalyticsByDatasetUUIDQuery } from "../../../../../utils/redux/queries/dataset.queries"
import { formatBytes } from "../../../../../utils/utils"

type Props = {
  dataset: DataSet
}

const TabularDataAnalytics = (p: Props) => {
  const { data, isFetching, error } = useGetDataAnalyticsByDatasetUUIDQuery({ datasetUuid: p.dataset.uuid })

  if (isFetching) {
    return (
      <Box display={"flex"} sx={{ ml: 2 }}>
        <Typography sx={{ mr: 2 }} color={colorPalette.deepPurpleO60}>
          Loading analytics
        </Typography>
        <Loader size={25} />
      </Box>
    )
  }

  if (error) return null

  const sizeInMb = data?.size ? formatBytes(data.size) : ""

  return (
    <Box
      display={"flex"}
      flexWrap={"wrap"}
      alignItems={"center"}
      color={colorPalette.deepPurpleO70}
      sx={{ textWrap: "nowrap", fontSize: "14px", pl: 2 }}>
      <Typography sx={{ mr: 4, fontSize: "inherit" }}>{`Size: ${sizeInMb}`}</Typography>
      <Typography sx={{ mr: 4, fontSize: "inherit" }}>{`Number of columns: ${data?.columns.length}`}</Typography>
      <Typography sx={{ fontSize: "inherit" }}>{`Number of rows: ${data?.num_rows}`}</Typography>
    </Box>
  )
}

export default React.memo(TabularDataAnalytics)
