import { Box, styled } from "@mui/material"

const ResultsWrapper = styled(Box)`
  direction: rtl;
  overflow: auto;
  max-height: calc(100vh - 200px);
`

const Results = styled(Box)`
  direction: ltr;
  flex: 0.7;
`

const LoaderWrapper = styled(Box)`
  display: flex;
  justify-content: center;
`

export const S = {
  ResultsWrapper,
  Results,
  LoaderWrapper,
}
