import { Box, Grid } from "@mui/material"
import React, { useState } from "react"
import KeyValueDisplay from "../../../../components/KeyValueDisplay/KeyValueDisplay"
import { useResourceAccess } from "../../../../utils/auth/userHooks"
import { type DataSet } from "../../../../utils/frontendTypes/datasets.types"
import EditFacetsDialog from "./components/EditFacetsDialog/EditFacetsDialog"
import HeaderWithEdit from "./components/HeaderWithEdit"

type Props = {
  dataset: DataSet
}

const DatasetFacets = (p: Props) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const openEditFacetsDialog = () => {
    setDialogOpen(true)
  }
  const closeEditFacetsDialog = () => {
    setDialogOpen(false)
  }

  const facets = p.dataset.otherFacets

  const { hasWriteAccess } = useResourceAccess(p.dataset.uuid)

  return (
    <>
      <Grid container direction={"column"} spacing={1}>
        <Grid item>
          <HeaderWithEdit
            headerText="Advanced metadata"
            clickEditFn={hasWriteAccess ? openEditFacetsDialog : undefined}
          />

          {facets && (
            <Box>
              {Object.entries(facets).map(([key, value]) => (
                <KeyValueDisplay key={key} objectKey={key} value={value} />
              ))}
            </Box>
          )}
        </Grid>
      </Grid>
      <EditFacetsDialog
        open={dialogOpen}
        closeFn={closeEditFacetsDialog}
        currentOtherFacets={p.dataset.otherFacets}
        currentSpecialFacets={p.dataset.specialFacets}
        datasetId={p.dataset.uuid}
      />
    </>
  )
}

export default React.memo(DatasetFacets)
