import RefreshIcon from "@mui/icons-material/Refresh"
import { Box, IconButton, List, Typography } from "@mui/material"
import React, { useCallback, useMemo } from "react"
import { Loader } from "../../../../../../../components/Loader/Loader"
import { StyledSectionHeader } from "../../../../../../../components/StyledTypography"
import { type DataSet } from "../../../../../../../utils/frontendTypes/datasets.types"
import { datasetsApi } from "../../../../../../../utils/redux/queries/dataset.queries"
import { RawFilesTags, useGetRawFilesInfoForDatasetQuery } from "../../../../../../../utils/redux/queries/raw.queries"
import { useAppDispatch, useAppSelector } from "../../../../../../../utils/redux/store"
import DisplayRawFileInfo from "./DisplayRawFileInfo"
import { createFileId } from "./rawFile.helpers"

type Props = {
  dataset: DataSet
}

const UploadedFilesRaw = (p: Props) => {
  const { data, isFetching } = useGetRawFilesInfoForDatasetQuery({ datasetId: p.dataset.nameId })
  const recentlyDeletedFileIds = useAppSelector(state => state.datasetsReducer.recentlyDeletedFileIds)
  const dispatch = useAppDispatch()

  const filteredData = useMemo(() => {
    if (!data) return []
    return data.results.filter(fileInfo => !recentlyDeletedFileIds.includes(createFileId(fileInfo)))
  }, [data, recentlyDeletedFileIds])

  const clickRefresh = useCallback(() => {
    dispatch(datasetsApi.util.invalidateTags([{ type: RawFilesTags.RawFilesInfoList as any, id: p.dataset.nameId }]))
  }, [dispatch, p.dataset.nameId])

  return (
    <>
      <Box display={"flex"} alignItems={"center"}>
        <StyledSectionHeader>{`Uploaded files${
          filteredData.length ? ` (${filteredData.length})` : ""
        }`}</StyledSectionHeader>

        <IconButton onClick={clickRefresh} sx={{ ml: 0.2 }}>
          <RefreshIcon sx={{ fontSize: 18 }} />
        </IconButton>
      </Box>

      {isFetching && (
        <Box sx={{ mt: 2, ml: 5 }}>
          <Loader size={25} />
        </Box>
      )}
      {!!filteredData?.length && !isFetching && (
        <List dense={true} sx={{ maxHeight: 260, overflow: "auto" }}>
          {filteredData.map(el => (
            <DisplayRawFileInfo key={"finfo_" + el.name} fileInfo={el} dataset={p.dataset} />
          ))}
        </List>
      )}
      {!filteredData?.length && !isFetching && <Typography>No uploaded files.</Typography>}
    </>
  )
}

export default React.memo(UploadedFilesRaw)
