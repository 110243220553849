import { type DataSet } from "../../../../utils/frontendTypes/datasets.types"
import { type Facets } from "../../../../utils/sdk/utils/entities/sdk.dataset.types"
import { type FormRuleType } from "../../../MyDataset/components/MyDatasetMetadata/components/EditMetadataDialog/editMetadataDialog.helpers"

type DataInteractionProps = {
  workspaceLink?: string
  webodvLink?: string
  apiIds: {
    nameId: string
    uuid: string
  }
}

const URL_REGEX =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/

// TODO: Temporary soultion. This should be generated based on updated dataset manifest when it's ready
export const generateDataInteractionProps = (dataset: DataSet): DataInteractionProps => ({
  workspaceLink: dataset.specialFacets ? dataset.specialFacets[SpecialFacetApiKey.workspaceLink] : undefined,
  webodvLink: dataset.specialFacets ? dataset.specialFacets[SpecialFacetApiKey.webOdvLink] : undefined,
  apiIds: {
    nameId: dataset.nameId,
    uuid: dataset.uuid,
  },
})

export enum SpecialFacetFormKey {
  webOdvLink = "webOdvLink",
  workspaceLink = "workspaceLink",
}

export enum SpecialFacetApiKey {
  webOdvLink = "has.webodv.link",
  workspaceLink = "has.notebook.link",
}

export type SpecialFacetsApi = Record<SpecialFacetApiKey, string>
export type SpecialFacetsForm = Record<SpecialFacetFormKey, string>

export const facetApiNames: Record<SpecialFacetFormKey, SpecialFacetApiKey> = {
  [SpecialFacetFormKey.webOdvLink]: SpecialFacetApiKey.webOdvLink,
  [SpecialFacetFormKey.workspaceLink]: SpecialFacetApiKey.workspaceLink,
}

export const facetFormNames: Record<SpecialFacetApiKey, SpecialFacetFormKey> = {
  [SpecialFacetApiKey.webOdvLink]: SpecialFacetFormKey.webOdvLink,
  [SpecialFacetApiKey.workspaceLink]: SpecialFacetFormKey.workspaceLink,
}

export const mapToFormKeys = (apiSpecialFacets: Partial<SpecialFacetsApi>): SpecialFacetsApi => {
  return Object.fromEntries(
    Object.entries(apiSpecialFacets).map(([key, value]) => {
      return [facetFormNames[key as SpecialFacetApiKey], value]
    })
  ) as SpecialFacetsApi
}

export const mapToApiKeys = (formSpecialFacets: SpecialFacetsForm): SpecialFacetsApi => {
  return Object.fromEntries(
    Object.entries(formSpecialFacets).map(([key, value]) => [facetApiNames[key as SpecialFacetFormKey], value])
  ) as SpecialFacetsApi
}

export const SpecialFacetRules: Partial<Record<SpecialFacetFormKey, FormRuleType>> = {
  [SpecialFacetFormKey.webOdvLink]: {
    pattern: {
      value: URL_REGEX,
      message: "Invalid URL",
    },
  },
  [SpecialFacetFormKey.workspaceLink]: {
    pattern: {
      value: URL_REGEX,
      message: "Invalid URL",
    },
  },
}

export const SpecialFacetKeys = Object.values(SpecialFacetFormKey)

export type SpecialFacetsType = Record<SpecialFacetFormKey, any>

export const SpecialFacetNames: Record<SpecialFacetFormKey, string> = {
  [SpecialFacetFormKey.webOdvLink]: "WebODV link",
  [SpecialFacetFormKey.workspaceLink]: "Workspace link",
}

export const SpecialFacetTypes: Record<SpecialFacetFormKey, "string" | "number" | "boolean"> = {
  [SpecialFacetFormKey.webOdvLink]: "string",
  [SpecialFacetFormKey.workspaceLink]: "string",
}

export const divideFacets = (
  facets?: Facets | null
): { specialFacets: Partial<SpecialFacetsApi> | undefined; otherFacets: Facets | undefined } => {
  if (!facets) return { specialFacets: undefined, otherFacets: undefined }
  const specialFacets: Partial<SpecialFacetsApi> = {}
  const otherFacets: Facets = {}

  Object.entries(facets).forEach(([key, value]) => {
    const keyIsSpecial = Object.values(facetApiNames).includes(key as SpecialFacetApiKey)
    const valueIsString = typeof value === "string"
    if (keyIsSpecial) {
      if (valueIsString) specialFacets[key as SpecialFacetApiKey] = value
      else console.error(`Special facet ${key} is not a string`)
    } else {
      otherFacets[key] = value
    }
  })

  return { specialFacets, otherFacets }
}
