import { Box, Grid } from "@mui/material"
import React, { useState } from "react"
import KeyValueDisplay from "../../../../../components/KeyValueDisplay/KeyValueDisplay"
import { useResourceAccess } from "../../../../../utils/auth/userHooks"
import { type DataSet } from "../../../../../utils/frontendTypes/datasets.types"
import HeaderWithEdit from "../components/HeaderWithEdit"
import EditLinksDialog from "./EditLinksDialog/EditLinksDialog"

type Props = {
  dataset: DataSet
}

const DatasetLinks = (p: Props) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const openEditLinksDialog = () => {
    setDialogOpen(true)
  }
  const closeEditLinksDialog = () => {
    setDialogOpen(false)
  }

  const specialFacets = p.dataset.specialFacets

  const { hasWriteAccess } = useResourceAccess(p.dataset.uuid)

  return (
    <>
      <Grid container direction={"column"} spacing={1}>
        <Grid item>
          <HeaderWithEdit headerText="Links" clickEditFn={hasWriteAccess ? openEditLinksDialog : undefined} />

          {specialFacets && (
            <Box>
              {Object.entries(specialFacets).map(([key, value]) => (
                <KeyValueDisplay key={key} objectKey={key} value={value} />
              ))}
            </Box>
          )}
        </Grid>
      </Grid>
      <EditLinksDialog
        open={dialogOpen}
        closeFn={closeEditLinksDialog}
        currentSpecialFacets={p.dataset.specialFacets}
        currentOtherFacets={p.dataset.otherFacets}
        datasetId={p.dataset.uuid}
      />
    </>
  )
}

export default React.memo(DatasetLinks)
