import { createCollectionRefernceName } from "../../api/requests.utils"
import { type ApiResponse } from "../../api/response.types"
import { getResourceKindForResourceType } from "../../frontendTypes/datasetType.helpers"
import type { DataCollectionDtoGet } from "../utils/entities/sdk.dataCollection.types"
import { type APIResultsDto, type DatasetDtoGet } from "../utils/entities/sdk.dataset.types"
import type { ObservableDtoGet } from "../utils/entities/sdk.observable.types"
import {
  INTERNALY_SHARED_LABEL,
  ResourceKind,
  ResourceTypeOdp,
  type JsonLdDocument,
  type OqsCondition,
  type ResourceRequestParams,
} from "../utils/entities/sdk.resource.types"
import { API_URL, generateCaseInsensitivePattern } from "../utils/requests/sdk.request.helpers"
import type { requestFnType, RequestOptions } from "../utils/requests/sdk.request.types"
import { standardJsonRequest } from "../utils/requests/sdk.requests"
import { OQS } from "./oqs.helpers"

export class CatalogSDK {
  requestFn: requestFnType

  constructor(requestFn: requestFnType) {
    this.requestFn = requestFn
  }

  async getDatasetSearchResult(
    params: ResourceRequestParams & { collectionId?: string }
  ): Promise<ApiResponse<APIResultsDto<DatasetDtoGet>>> {
    const oqsCollectionIdCondition = params.collectionId
      ? [{ "#EQUALS": ["$spec.data_collection", createCollectionRefernceName(params.collectionId)] }]
      : undefined

    return await this.getResourceSearchResults<DatasetDtoGet>({
      ...params,
      kind: ResourceKind.dataset,
      resourceAvailability: params.isPublic ? "public" : "private",
      otherOqsConditions: oqsCollectionIdCondition,
    })
  }

  async getResourceSearchResults<T>({
    typedSearchString,
    kind,
    searchFields = ["description", "display_name", "name", "spec.distribution.license.name", "spec.distribution.license.full_text"],
    filterIds,
    searchInTags = true,
    resourceAvailability,
    otherOqsConditions = [],
    isInternallyShared,
    pageSize,
    page,
  }: ResourceRequestParams & {
    kind: ResourceKind
    searchFields?: string[]
    otherOqsConditions?: OqsCondition[]
    resourceAvailability: "public" | "private"
    pageSize?: number
    page?: string
  }): Promise<ApiResponse<APIResultsDto<T>>> {
    const pattern = typedSearchString ? generateCaseInsensitivePattern(typedSearchString) : undefined

    const oqsConditions: OqsCondition[] = [OQS.hasKind(kind)]

    if (resourceAvailability === "public") {
      oqsConditions.push(OQS.isPublic)
    }

    if (resourceAvailability === "private") {
      oqsConditions.push(OQS.isPrivate)
    }

    if (isInternallyShared === true) {
      oqsConditions.push({
        "#EQUALS": [`$labels.'${INTERNALY_SHARED_LABEL}'`, true],
      })
    }

    if (isInternallyShared === false) {
      oqsConditions.push({
        "#OR": [
          { "#IS_NULL": [`$labels.'${INTERNALY_SHARED_LABEL}'`] },
          { "#EQUALS": [`$labels.'${INTERNALY_SHARED_LABEL}'`, false] },
        ],
      })
    }
    // if isInternallyShared is undefined we don't check for the label

    if (searchFields?.length && pattern) {
      oqsConditions.push({
        "#OR": searchFields.map(el => ({ "#REGEX_LIKE": [`$${el}`, pattern] })),
      })
    }
    if (filterIds?.length) oqsConditions.push({ "#WITHIN": ["$metadata.name", [...filterIds]] })
    if (searchInTags && typedSearchString) {
      oqsConditions[2]["#OR"]?.push(
        { "#REGEX_LIKE": ["$spec.tags", pattern] }
      )
    }

    const params: RequestOptions["params"] = {}
    if (pageSize) params.page_size = pageSize
    if (page) params.page = page

    return await this.requestFn("catalog/list", {
      method: "POST",
      body: {
        "#AND": [...oqsConditions, ...otherOqsConditions],
      },
      params,
    })
  }

  async getResourceSearchReslutOpen({
    typedSearchString,
    resourceKind,
    page,
    pageSize,
  }: {
    resourceKind: ResourceKind
  } & ResourceRequestParams): Promise<ApiResponse<APIResultsDto<DatasetDtoGet | DataCollectionDtoGet>>> {
    const url = new URL(`${API_URL}/api/public/catalog/v1/metadata/search/`)

    url.searchParams.append("kind", resourceKind)
    if (typedSearchString) url.searchParams.append("keywords", typedSearchString)

    if (page) {
      const params = new URLSearchParams(page)
      params.forEach((value, key) => {
        url.searchParams.append(key, value)
      })
    }

    if (pageSize) url.searchParams.append("page_size", pageSize.toString())

    return await standardJsonRequest([url, { method: "GET" }])
  }

  async getDatasetSearchResultOpen(
    params: ResourceRequestParams & { collectionId?: string }
  ): Promise<ApiResponse<APIResultsDto<DatasetDtoGet>>> {
    return (await this.getResourceSearchReslutOpen({
      ...params,
      resourceKind: ResourceKind.dataset,
    })) as ApiResponse<APIResultsDto<DatasetDtoGet>>
  }

  async getCollectionsSearchResultOpen(
    params: ResourceRequestParams & { collectionId?: string }
  ): Promise<ApiResponse<APIResultsDto<DataCollectionDtoGet>>> {
    return (await this.getResourceSearchReslutOpen({
      ...params,
      resourceKind: ResourceKind.collection,
    })) as ApiResponse<APIResultsDto<DataCollectionDtoGet>>
  }

  async getRelatedResources({
    resourceKind,
    ref,
  }: {
    resourceKind: ResourceKind
    ref: string
  }): Promise<ApiResponse<APIResultsDto<DatasetDtoGet | ObservableDtoGet<any>>>> {
    const url = new URL(`${API_URL}/api/public/catalog/v1/metadata/`)

    url.searchParams.append("kind", resourceKind)
    url.searchParams.append("ref", ref)

    return await standardJsonRequest([url, { method: "GET" }])
  }

  async getCollectionDatasets({
    collectionId,
  }: {
    collectionId: string
  }): Promise<ApiResponse<APIResultsDto<DatasetDtoGet>>> {
    const ref = createCollectionRefernceName(collectionId)

    return (await this.getRelatedResources({
      resourceKind: ResourceKind.dataset,
      ref,
    })) as ApiResponse<APIResultsDto<DatasetDtoGet>>
  }

  async getResourceByIdOpen({
    resourceId,
    resourceType,
  }: {
    resourceId: string
    resourceType: ResourceTypeOdp
  }): Promise<ApiResponse<DatasetDtoGet | DataCollectionDtoGet>> {
    const ref = `${getResourceKindForResourceType(resourceType)}/${resourceId}`

    const url = new URL(`${API_URL}/api/public/catalog/v1/metadata/`)
    url.searchParams.append("qualified_name", ref)

    return await standardJsonRequest([url, { method: "GET" }])
  }

  async getCollectionByIdOpen(collectionId: string): Promise<ApiResponse<DataCollectionDtoGet | undefined>> {
    return (await this.getResourceByIdOpen({
      resourceId: collectionId,
      resourceType: ResourceTypeOdp.Collection,
    })) as ApiResponse<DataCollectionDtoGet>
  }

  async getDatasetByIdOpen(datasetsId: string): Promise<ApiResponse<DatasetDtoGet>> {
    return (await this.getResourceByIdOpen({
      resourceId: datasetsId,
      resourceType: ResourceTypeOdp.Dataset,
    })) as ApiResponse<DatasetDtoGet>
  }

  async getObservablesForResourceOpen({
    resourceId,
    resourceType,
  }: {
    resourceId: string
    resourceType: ResourceTypeOdp
  }): Promise<ApiResponse<APIResultsDto<ObservableDtoGet<any>>>> {
    const ref = `${getResourceKindForResourceType(resourceType)}/${resourceId}`

    return this.getRelatedResources({
      resourceKind: ResourceKind.observable,
      ref,
    }) as unknown as ApiResponse<APIResultsDto<ObservableDtoGet<any>>>
  }

  async getJsonLdByNameIdOpen(params: {
    resourceType: ResourceTypeOdp
    nameId: string
  }): Promise<ApiResponse<JsonLdDocument>> {
    const url = new URL(`${API_URL}/api/public/catalog/v1/jsonld/`)
    const ref = `${getResourceKindForResourceType(params.resourceType)}/${params.nameId}`
    url.searchParams.append("qualified_name", ref)

    return await standardJsonRequest([url, { method: "GET" }])
  }
}
