import type { DataDetailsValue } from "../../../../pages/CatalogCollection/components/DataDetailsTable/DataDetailsTable"
import { type Modify, type Nullable, type Subset } from "../../../utils"
import type { ApiNoneType } from "../../helpers"
import type {
  MetadataDtoCreate,
  ResourceDtoCreate,
  ResourceKind,
  ResourceMetadataDtoGet,
  ResourceStatus,
  StorageClass,
  StorageController,
} from "./sdk.resource.types"

export type CitationDto = {
  cite_as?: string | null // Directions on how to cite the dataset
  doi?: string | null // Digital Object Identifier ([DOI](doi.org))
}

export type LicenseDto = Nullable<{
  name?: string // License name. Can be set to `Proprietary` for proprietary licenses
  href?: string // HREF to license text
  full_text?: string // Full license text
}>

export type ContactInfoDto = Nullable<{
  contact?: string // Contact information in the form 'Lastname, Firstname <email@domain.com>'
  organisation?: string // Contact affiliation such as organisation name, etc
}>

/** Dataset attributes */
export type AttributeDto = {
  name: string
  description?: string
  traits: string[]
}

export type Facets = Record<string, DataDetailsValue>
export type CollectionRef = `${ResourceKind.collection}/${string}`
export const DATASET_VERSION = "v1alpha3"

export type DatasetSpecCreate = Nullable<{
  maintainer?: ContactInfoDto // Active maintainer information
  citation?: CitationDto | ApiNoneType
  documentation?: string[] // Links to any relevant documentation
  attributes?: AttributeDto[]
  tags?: string[]
  facets?: Facets
  data_collection?: CollectionRef
}> & {
  storage_class: StorageClass // Storage class qualified name
  storage_controller: StorageController // Storage controller qualified name
}

export type DatasetSpecGet = DatasetSpecCreate

// For SDK
export type DatasetDtoCreate = Modify<
  ResourceDtoCreate,
  { spec: DatasetSpecCreate; kind: ResourceKind.dataset; version: typeof DATASET_VERSION }
>
export type DatasetDtoGet = Modify<DatasetDtoCreate, { status: ResourceStatus; metadata: ResourceMetadataDtoGet }>

type FillableDatasetSpec = Partial<Omit<DatasetSpecCreate, "storage_class" | "storage_controller">>

export type EditDatasetDto = Subset<
  Omit<DatasetDtoCreate, "kind" | "version" | "spec" | "metadata"> & {
    spec: FillableDatasetSpec
    metadata: Omit<MetadataDtoCreate, "name">
  }
>

export type EditMetadataDto = Omit<EditDatasetDto, "metadata">
export type EditFacetsDto = Modify<Pick<EditDatasetDto, "spec">, { spec: Pick<FillableDatasetSpec, "facets"> }>

export type APIResultsDto<T> = {
  results: T[]
  prev: null | string
  next: null | string
  num_results: number
  total_count?: number
}

export type APIResultsRawFiles<T> = Omit<APIResultsDto<T>, "prev">
