import { Box, LinearProgress, Typography } from "@mui/material"
import React from "react"

interface Props {
  progress: number
}

const UploadProgress: React.FC<Props> = ({ progress }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress variant="determinate" value={progress} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${progress}%`}</Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default UploadProgress
