import { ResourceKind } from "../../sdk/utils/entities/sdk.resource.types"
import {
  type AccessRequest,
  type AccessRoleId,
  type CreateResourceAccessDto,
  type PermissionApi,
  type RemoveResourceAccessDto,
  type UserIdentifier,
  type UserType,
} from "./resourceAccess.types"

export const createSubjectDto = ({
  userEmail,
  roleId,
  resourceId,
  userId,
  userType,
}: {
  roleId: AccessRoleId
  resourceId: string
  userType: UserType
} & UserIdentifier): CreateResourceAccessDto => {
  return {
    object: {
      id: resourceId,
      kind: ResourceKind.collection,
    },
    role: roleId,

    subject: {
      email: userEmail,
      id: userId,
      type: userType,
    },
  }
}

export const createDeleteSubjectDto = ({
  subjectId,
  roleId,
  resourceId,
  subjectType,
}: {
  subjectId: string
  roleId: AccessRoleId
  resourceId: string
  subjectType: UserType
}): RemoveResourceAccessDto => {
  return {
    object: {
      id: resourceId,
      kind: ResourceKind.collection,
    },
    role: roleId,

    subject: {
      id: subjectId,
      type: subjectType,
    },
  }
}

export const mapToPermission = (apiPermission: PermissionApi): AccessRequest => {
  return {
    ...apiPermission,
    requested: new Date(apiPermission.requested),
    updated: new Date(apiPermission.updated),
  }
}
