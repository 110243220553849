import { Typography } from "@mui/material"
import React, { useState } from "react"
import { colorPalette } from "../../styling/theme"

type Props = {
  text: string
  maxLength?: number
}

const ShowMoreText = (p: Props) => {
  const [showsMore, setShowsMore] = useState(false)
  const maxLength = p.maxLength ?? 60
  if (p.text.length < maxLength) return <>{p.text}</>
  const shortText = p.text.substring(0, maxLength)

  return (
    <>
      {showsMore ? p.text + " " : shortText + "... "}
      <Typography
        sx={{ fontSize: "inherit", p: 0, cursor: "pointer", color: colorPalette.surfieGreen }}
        component={"span"}
        onClick={() => setShowsMore(!showsMore)}>
        {showsMore ? "show less" : "show more"}
      </Typography>
    </>
  )
}

export default React.memo(ShowMoreText)
