import { Box } from "@mui/material"
import React from "react"
import CsvExportButtonTrigger from "../../../../components/CsvExportButtonTrigger"
import { colorPalette } from "../../../../styling/theme"
import type { DataSet } from "../../../../utils/frontendTypes/datasets.types"
import { S } from "../../../CatalogCollection/CatalogCollection.style"
import CatalogSampleTable from "../CatalogSampleTable"
import TabularDataAnalytics from "./components/TabularDataAnalytics"

type Props = {
  dataset: DataSet
}

const CatalogTabularData = (p: Props) => {
  return (
    <>
      <Box
        display={"flex"}
        flexWrap={"wrap"}
        alignItems={"center"}
        color={colorPalette.deepPurpleO70}
        sx={{ textWrap: "nowrap", mb: 1, mt: 1 }}>
        <S.SectionHeader sx={{ mr: 5, color: colorPalette.deepPurple }}>Data</S.SectionHeader>

        <Box sx={{ ml: "auto", mt: { xs: 1, sm: 0 } }}>
          <CsvExportButtonTrigger datasetNameId={p.dataset.nameId} />
        </Box>
      </Box>
      <Box display={"flex"} flexWrap={"wrap"} sx={{ mb: 1.5 }}>
        <TabularDataAnalytics dataset={p.dataset} />
      </Box>

      <CatalogSampleTable datasetNameId={p.dataset.nameId} />
    </>
  )
}

export default React.memo(CatalogTabularData)
