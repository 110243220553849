import React from "react"

import { type SxProps } from "@mui/material"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableRow from "@mui/material/TableRow"
import MetadataValue from "../../../../components/KeyValueDisplay/components/MetadataValue"

export type DataDetailsValue = string | null | number | undefined | Record<any, any> | any[]
export type DataDetailsTableProps = {
  data: Record<string, DataDetailsValue>
  links?: string[]
  valuesWithCopyEnabled?: string[]
  cellSx?: SxProps
}

const DataDetailsTable = (p: DataDetailsTableProps) => {
  return (
    <TableContainer>
      <Table sx={{ minWidth: 0 }} size="small">
        <TableBody>
          {Object.entries(p.data).map(([key, value]) => {
            if (!value) return null
            return (
              <TableRow key={key}>
                <TableCell
                  align="left"
                  sx={{ border: "none", pl: 0, fontWeight: "bold", verticalAlign: "top", ...p.cellSx }}>
                  {key}
                </TableCell>
                <TableCell align="left" sx={{ border: "none", verticalAlign: "top", ...p.cellSx }}>
                  <MetadataValue
                    value={value}
                    enableCopying={!!p.valuesWithCopyEnabled?.includes(key)}
                    stringMaxLength={Infinity}
                  />
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default React.memo(DataDetailsTable)
