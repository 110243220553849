export enum AllSchemaDataTypes {
  String = "string",
  Long = "long",
  Boolean = "boolean",
  Int = "int",
  Double = "double",
  Date = "date",
  Binary = "binary",
  Geometry = "geometry",
  Time = "time",
  Timestamp = "timestamp",
  List = "list",
}

export enum ListSchemaDataType {
  Long = "list<long>",
  Boolean = "list<boolean>",
  Int = "list<int>",
  Double = "list<double>",
  Date = "list<date>",
  String = "list<string>",
  Binary = "list<binary>",
}

export enum TimePrecisionSchemaDataType {
  Microseconds = "us",
  Nanoseconds = "ns",
}

export enum TimestampStringPrecisionSchemaDataType {
  Second = "s",
  Millisecond = "ms",
  Microseconds = "us",
}

export enum TimestampVersionSchemaDataType {
  String = "string",
  Number = "umber",
}

export enum TimestampPrecisionSchemaDataType {
  Second = "s",
  Millisecond = "ms",
  Microseconds = "us",
  Nanoseconds = "ns",
}

export enum TimestampFormatSchemaDataType {
  YYYYMMDDHHMMSS = "%Y%m%d %H%M%S",
  YYYYDDMMHHMMSS = "%Y%d%m %H%M%S",
  DDMMYYYYHHMMSS = "%d%m%Y %H%M%S",
}

export type SchemaField = {
  name: string
  type: string
  metadata?: {
    format?: string
    big?: "1"
  } | null
}

export type SchemaFieldDto = {
  type: string
  metadata?: Record<string, any>
  nullable?: boolean
}

type TableMetadataDto = {
  geometry?: {
    primary_location: string
  }
}

type PartitioningInfo = {
  columns: string[]
  transformer_name: string
  args?: (number | string)[]
}

type TableSchema = Record<string, SchemaFieldDto>

/**
 * API returns this when requesting schema for dataset
 */
export type TableSchemaGetDto = {
  name: string
  table_schema: TableSchema
  partitioning?: PartitioningInfo[]
  metadata_path: string
  commit_path: string
  commit_lock_path: string
  stage_path: string
  stage_data_path: string
  version: number
  specification_version: string
}

export type CreateSchemaDto = {
  table_schema: TableSchema
  table_description?: string
  geospatial_partition_columns?: string[]
  geospatial_partition_hash_precision?: number
  table_metadata?: TableMetadataDto
}

export const createTableSchemaDto = (schemaFields: SchemaField[]): CreateSchemaDto => {
  const tableSchema: TableSchema = {}
  schemaFields.forEach(el => (tableSchema[el.name] = { type: el.type }))

  return {
    table_schema: tableSchema,
  }
}

export type DatasetRow = Record<string, any>

export type DataPointsDto = {
  data: DatasetRow[]
}

export type DataPointsGetDto = {
  data: DatasetRow[]
}

export type DataPointsNDJsonDto = DatasetRow[]

export type DtaPointsCreateResponse = {
  status: string
  message: string
}

export type DataPointsCreateDto = {
  data: DatasetRow[]
  stage_id?: string
}

export type AnalyticsDto = {
  num_files: number
  num_rows: number
  size: number
  columns: SchemaField[]
}
