import type { ResourceGroup, ResourceKind } from "../../sdk/utils/entities/sdk.resource.types"
import type { Modify } from "../../utils"

export enum AccessRoleId {
  Viewer = "3",
  Editor = "2",
  Admin = "1",
}

export enum UserType {
  user = "user",
  service = "service",
  group = "group",
}

export type AccessGroup = {
  id: string
  name: string
  description: string
}

export type ResourceAccess = {
  object: RelationshipObject
  role: AccessRoleId
  subject: ResourceSubject
}

export type CreateResourceAccessDto = {
  object: RelationshipObject
  role: AccessRoleId
  subject: RelationshipSubject
}

export type RemoveResourceAccessDto = {
  object: {
    id: string
    kind: ResourceKind
  }
  role: AccessRoleId
  subject: RelationshipSubjectId
}

interface SubjectBase {
  id: string
  type: UserType
}

export interface SubjectUser extends SubjectBase {
  email: string
  type: UserType.user
}

export interface SubjectGroup extends SubjectBase {
  name: string
  type: UserType.group
}

export interface SubjectService extends SubjectBase {
  name: string
  type: UserType.service
}

export type ResourceSubject = SubjectUser | SubjectGroup | SubjectService

type RelationshipSubjectId = {
  id: string
  type: UserType
}

type RelationshipSubject = { email?: string; id?: string; type: UserType; name?: string }

type RelationshipObject = {
  id: string
  kind: ResourceKind
}

export type UserIdentifier = { userEmail: string; userId?: string } | { userId: string; userEmail?: string }

// ### Waitlist types ###
export type WaitlistUser = {
  email: string
  id: string
  fullname: string
  org?: string
  country?: string
  role?: string
  social?: {
    linkedin?: string
  }
}

export enum AccessRequestStatus {
  Pending = "pending",
  Approved = "approved",
  Rejected = "rejected",
}

export enum WorkspacePermissions {
  Basic = "8fa722ae-ff94-482a-b908-5df3aee7b233",
  SmallNotebook = "cd8a0494-784e-4499-ac51-21aeb88bcb95",
}

export type AccessRequestPermissions = {
  workspaces: WorkspacePermissions[]
  resource_groups: ResourceGroup[]
}

export type PermissionApi = {
  permission_id: string
  user: WaitlistUser
  status: AccessRequestStatus
  requested: string
  updated: string
  permissions: AccessRequestPermissions
}

export type AccessRequest = Modify<PermissionApi, { requested: Date; updated: Date }>
