import { Box, type SxProps } from "@mui/material"
import React from "react"
import { StyledMetadataKey } from "../StyledTypography"
import MetadataValue from "./components/MetadataValue"

type Props = {
  objectKey: string
  value: unknown
  sx?: SxProps
  valueSx?: SxProps
}

const KeyValueDisplay = (p: Props) => {
  return (
    <Box display={"flex"} sx={{ fontSize: "0.875rem", my: 0.5, ...p.sx }}>
      <StyledMetadataKey
        sx={{
          minWidth: "130px",
          maxWidth: "130px",
          mr: 1,
        }}>
        {p.objectKey + ":"}
      </StyledMetadataKey>
      {!!p.value && <MetadataValue value={p.value} sx={{ ...p.valueSx }} />}
    </Box>
  )
}

export default React.memo(KeyValueDisplay)
